import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { apiService } from "../../common/api/apiService";
import {
  ContractTerminationSearchRequest,
  ContractTerminationSearchResult,
  GenerateContractTermination
} from "./types";

const SEARCH_CONTRACT_FOR_TERMINATION = ApiRequestAdapter.create("/contract-terminations/search", "GET");
const GENERATE_CONTRACT_TERMINATION = ApiRequestAdapter.create("/contract-terminations/generate", "POST");

export const requests = {
  SEARCH_CONTRACT_FOR_TERMINATION,
  GENERATE_CONTRACT_TERMINATION
};

const api = {
  searchContractForTermination: (
    request: ContractTerminationSearchRequest
  ): AxiosPromise<ContractTerminationSearchResult> => {
    return apiService.get(SEARCH_CONTRACT_FOR_TERMINATION.url, { params: request });
  },
  generateContractTermination: (request: GenerateContractTermination): AxiosPromise<Blob> => {
    return apiService.post(GENERATE_CONTRACT_TERMINATION.url, request, { responseType: "blob" });
  }
};

export default api;
