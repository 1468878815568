import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { apiService } from "../../common/api/apiService";
import {
  EntityIdObject,
  EntityObject,
  SearchPageRequest,
  SearchPageResult,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../common/types";
import { serializeParams } from "../../common/utils/apiUtils";
import {
  Agent,
  AgentAttachment,
  AgentBranding,
  AgentBrokerNumber,
  AgentCompetence,
  AgentCompetenceFilterPageRequest,
  AgentCompetenceFilterPageResult,
  AgentDirectorate,
  AgentFilterExportRequest,
  AgentFilterPageRequest,
  AgentFilterPageResult,
  AgentLicense,
  AgentProfilePicture,
  AgentSearchRequest,
  AgentSearchResult,
  AgentTree,
  AgentTreeFilterRequest,
  CreateUpdateAgent,
  CreateUpdateAgentBranding,
  CreateUpdateAgentBrokerNumber,
  CreateUpdateAgentCompetence,
  CreateUpdateAgentDirectorate,
  CreateUpdateAgentLicense
} from "./types";

const FILTER_AGENTS = ApiRequestAdapter.create("/agents", "GET");
const DOWNLOAD_AGENTS_EXPORT = ApiRequestAdapter.create("/agents/export", "GET");
const GET_AGENT_TREE = ApiRequestAdapter.create("/agents/tree", "GET");
const SEARCH_AGENT = ApiRequestAdapter.create("/agents/search", "GET");
const GET_AGENT = ApiRequestAdapter.create("/agents/{0}", "GET");
const CREATE_AGENT = ApiRequestAdapter.create("/agents", "POST");
const GET_AGENT_PARTNER_ID_NUMBER = ApiRequestAdapter.create("/agents/partner-id-number", "GET");
const UPDATE_AGENT = ApiRequestAdapter.create("/agents/{0}", "PUT");
const DELETE_AGENT = ApiRequestAdapter.create("/agents/{0}", "DELETE");

const DOWNLOAD_PROFILE_PICTURE = ApiRequestAdapter.create("/agents/{0}/profile-picture", "GET");
const UPDATE_PROFILE_PICTURE = ApiRequestAdapter.create("/agents/{0}/profile-picture", "PUT");
const DELETE_PROFILE_PICTURE = ApiRequestAdapter.create("/agents/{0}/profile-picture", "DELETE");

const UPLOAD_AGENT_ATTACHMENTS = ApiRequestAdapter.create("/agents/{0}/attachments", "POST");
const DOWNLOAD_AGENT_ATTACHMENT = ApiRequestAdapter.create("/agents/{0}/attachments/{1}", "GET");
const DOWNLOAD_AGENT_ATTACHMENTS_AS_ZIP = ApiRequestAdapter.create("/agents/{0}/attachments/all", "GET");
const DELETE_AGENT_ATTACHMENT = ApiRequestAdapter.create("/agents/{0}/attachments/{1}", "DELETE");

const CREATE_AGENT_BRANDING = ApiRequestAdapter.create("/agents/{0}/branding", "POST");
const GET_AGENT_BRANDING = ApiRequestAdapter.create("/agents/{0}/branding", "GET");
const UPDATE_AGENT_BRANDING = ApiRequestAdapter.create("/agents/{0}/branding/{1}", "PUT");
const DELETE_AGENT_BRANDING = ApiRequestAdapter.create("/agents/{0}/branding/{1}", "DELETE");

const FILTER_AGENT_ENDING_COMPETENCES = ApiRequestAdapter.create("/agents/ending-competences", "POST");
const GET_AGENT_COMPETENCES = ApiRequestAdapter.create("/agents/{0}/competences", "GET");
const CREATE_AGENT_COMPETENCE = ApiRequestAdapter.create("/agents/{0}/competences", "POST");
const UPDATE_AGENT_COMPETENCE = ApiRequestAdapter.create("/agents/{0}/competences/{1}", "PUT");
const DELETE_AGENT_COMPETENCE = ApiRequestAdapter.create("/agents/{0}/competences/{1}", "DELETE");

const GET_AGENT_LICENSES = ApiRequestAdapter.create("/agents/{0}/licenses", "GET");
const CREATE_AGENT_LICENSE = ApiRequestAdapter.create("/agents/{0}/licenses", "POST");
const UPDATE_AGENT_LICENSE = ApiRequestAdapter.create("/agents/{0}/licenses/{1}", "PUT");
const DELETE_AGENT_LICENSE = ApiRequestAdapter.create("/agents/{0}/licenses/{1}", "DELETE");

const GET_AGENT_BROKER_NUMBERS = ApiRequestAdapter.create("/agents/{0}/broker-numbers", "GET");
const CREATE_AGENT_BROKER_NUMBER = ApiRequestAdapter.create("/agents/{0}/broker-numbers", "POST");
const UPDATE_AGENT_BROKER_NUMBER = ApiRequestAdapter.create("/agents/{0}/broker-numbers/{1}", "PUT");
const DELETE_AGENT_BROKER_NUMBER = ApiRequestAdapter.create("/agents/{0}/broker-numbers/{1}", "DELETE");

const FILTER_AGENT_DIRECTORATES = ApiRequestAdapter.create("/agent-directorates", "GET");
const CREATE_AGENT_DIRECTORATE = ApiRequestAdapter.create("/agent-directorates", "POST");
const UPDATE_AGENT_DIRECTORATE = ApiRequestAdapter.create("/agent-directorates/{0}", "PUT");
const DELETE_AGENT_DIRECTORATE = ApiRequestAdapter.create("/agent-directorates/{0}", "DELETE");

export const requests = {
  FILTER_AGENTS,
  DOWNLOAD_AGENTS_EXPORT,
  GET_AGENT_TREE,
  SEARCH_AGENT,
  GET_AGENT,
  CREATE_AGENT,
  GET_AGENT_PARTNER_ID_NUMBER,
  UPDATE_AGENT,
  DELETE_AGENT,
  DOWNLOAD_PROFILE_PICTURE,
  UPDATE_PROFILE_PICTURE,
  DELETE_PROFILE_PICTURE,
  UPLOAD_AGENT_ATTACHMENTS,
  DOWNLOAD_AGENT_ATTACHMENT,
  DOWNLOAD_AGENT_ATTACHMENTS_AS_ZIP,
  DELETE_AGENT_ATTACHMENT,
  CREATE_AGENT_BRANDING,
  GET_AGENT_BRANDING,
  UPDATE_AGENT_BRANDING,
  DELETE_AGENT_BRANDING,
  FILTER_AGENT_ENDING_COMPETENCES,
  GET_AGENT_COMPETENCES,
  CREATE_AGENT_COMPETENCE,
  UPDATE_AGENT_COMPETENCE,
  DELETE_AGENT_COMPETENCE,
  GET_AGENT_LICENSES,
  CREATE_AGENT_LICENSE,
  UPDATE_AGENT_LICENSE,
  DELETE_AGENT_LICENSE,
  GET_AGENT_BROKER_NUMBERS,
  CREATE_AGENT_BROKER_NUMBER,
  UPDATE_AGENT_BROKER_NUMBER,
  DELETE_AGENT_BROKER_NUMBER,
  FILTER_AGENT_DIRECTORATES,
  CREATE_AGENT_DIRECTORATE,
  UPDATE_AGENT_DIRECTORATE,
  DELETE_AGENT_DIRECTORATE
};

const api = {
  filterAgents: (request: AgentFilterPageRequest): AxiosPromise<AgentFilterPageResult> => {
    return apiService.get(FILTER_AGENTS.url, { params: request, paramsSerializer: { serialize: serializeParams } });
  },
  downloadAgentsExport: (request: AgentFilterExportRequest): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_AGENTS_EXPORT.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams },
      responseType: "blob"
    });
  },
  getAgentTree: (request: AgentTreeFilterRequest): AxiosPromise<AgentTree> => {
    return apiService.get(GET_AGENT_TREE.url, { params: request });
  },
  searchAgent: (request: AgentSearchRequest): AxiosPromise<AgentSearchResult> => {
    return apiService.get(SEARCH_AGENT.url, { params: request });
  },
  getAgent: (request: EntityIdObject): AxiosPromise<Agent> => {
    return apiService.get(GET_AGENT.getParametrizedUrl(request.id));
  },
  createAgent: (request: CreateUpdateAgent): AxiosPromise<Agent> => {
    return apiService.post(CREATE_AGENT.url, request);
  },
  getAgentPartnerIdNumber: (): AxiosPromise<string> => {
    return apiService.get(GET_AGENT_PARTNER_ID_NUMBER.url);
  },
  updateAgent: (request: EntityObject<CreateUpdateAgent>): AxiosPromise<Agent> => {
    return apiService.put(UPDATE_AGENT.getParametrizedUrl(request.id), request.object);
  },
  deleteAgent: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_AGENT.getParametrizedUrl(request.id));
  },
  downloadProfilePicture: (request: EntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_PROFILE_PICTURE.getParametrizedUrl(request.id), { responseType: "blob" });
  },
  updateProfilePicture: (request: EntityObject<FormData>): AxiosPromise<AgentProfilePicture> => {
    return apiService.put(UPDATE_PROFILE_PICTURE.getParametrizedUrl(request.id), request.object, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  deleteProfilePicture: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_PROFILE_PICTURE.getParametrizedUrl(request.id));
  },
  uploadAgentAttachments: (request: EntityObject<FormData>): AxiosPromise<AgentAttachment[]> => {
    return apiService.post(UPLOAD_AGENT_ATTACHMENTS.getParametrizedUrl(request.id), request.object, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  downloadAgentAttachment: (request: TwoLevelEntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_AGENT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2), {
      responseType: "blob"
    });
  },
  downloadAgentAttachmentsAsZip: (request: EntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_AGENT_ATTACHMENTS_AS_ZIP.getParametrizedUrl(request.id), {
      responseType: "blob"
    });
  },
  deleteAgentAttachment: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_AGENT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2));
  },
  createAgentBranding: (request: EntityObject<CreateUpdateAgentBranding>): AxiosPromise<AgentBranding> => {
    return apiService.post(CREATE_AGENT_BRANDING.getParametrizedUrl(request.id), request.object);
  },
  getAgentBranding: (request: EntityIdObject): AxiosPromise<AgentBranding> => {
    return apiService.get(GET_AGENT_BRANDING.getParametrizedUrl(request.id));
  },
  updateAgentBranding: (request: TwoLevelEntityObject<CreateUpdateAgentBranding>): AxiosPromise<AgentBranding> => {
    return apiService.put(UPDATE_AGENT_BRANDING.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteAgentBranding: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_AGENT_BRANDING.getParametrizedUrl(request.id1, request.id2));
  },
  filterAgentEndingCompetences: (
    request: AgentCompetenceFilterPageRequest
  ): AxiosPromise<AgentCompetenceFilterPageResult> => {
    return apiService.post(FILTER_AGENT_ENDING_COMPETENCES.url, request);
  },
  getAgentCompetences: (request: EntityIdObject): AxiosPromise<AgentCompetence[]> => {
    return apiService.get(GET_AGENT_COMPETENCES.getParametrizedUrl(request.id));
  },
  createAgentCompetence: (request: EntityObject<CreateUpdateAgentCompetence>): AxiosPromise<AgentCompetence> => {
    return apiService.post(CREATE_AGENT_COMPETENCE.getParametrizedUrl(request.id), request.object);
  },
  updateAgentCompetence: (
    request: TwoLevelEntityObject<CreateUpdateAgentCompetence>
  ): AxiosPromise<AgentCompetence> => {
    return apiService.put(UPDATE_AGENT_COMPETENCE.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteAgentCompetence: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_AGENT_COMPETENCE.getParametrizedUrl(request.id1, request.id2));
  },
  getAgentLicenses: (request: EntityIdObject): AxiosPromise<AgentLicense[]> => {
    return apiService.get(GET_AGENT_LICENSES.getParametrizedUrl(request.id));
  },
  createAgentLicense: (request: EntityObject<CreateUpdateAgentLicense>): AxiosPromise<AgentLicense> => {
    return apiService.post(CREATE_AGENT_LICENSE.getParametrizedUrl(request.id), request.object);
  },
  updateAgentLicense: (request: TwoLevelEntityObject<CreateUpdateAgentLicense>): AxiosPromise<AgentLicense> => {
    return apiService.put(UPDATE_AGENT_LICENSE.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteAgentLicense: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_AGENT_LICENSE.getParametrizedUrl(request.id1, request.id2));
  },
  getAgentBrokerNumbers: (request: EntityIdObject): AxiosPromise<AgentBrokerNumber[]> => {
    return apiService.get(GET_AGENT_BROKER_NUMBERS.getParametrizedUrl(request.id));
  },
  createAgentBrokerNumber: (request: EntityObject<CreateUpdateAgentBrokerNumber>): AxiosPromise<AgentBrokerNumber> => {
    return apiService.post(CREATE_AGENT_BROKER_NUMBER.getParametrizedUrl(request.id), request.object);
  },
  updateAgentBrokerNumber: (
    request: TwoLevelEntityObject<CreateUpdateAgentBrokerNumber>
  ): AxiosPromise<AgentBrokerNumber> => {
    return apiService.put(UPDATE_AGENT_BROKER_NUMBER.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteAgentBrokerNumber: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_AGENT_BROKER_NUMBER.getParametrizedUrl(request.id1, request.id2));
  },
  filterAgentDirectorates: (request: SearchPageRequest): AxiosPromise<SearchPageResult<AgentDirectorate>> => {
    return apiService.get(FILTER_AGENT_DIRECTORATES.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createAgentDirectorate: (request: CreateUpdateAgentDirectorate): AxiosPromise<AgentDirectorate> => {
    return apiService.post(CREATE_AGENT_DIRECTORATE.url, request);
  },
  updateAgentDirectorate: (request: EntityObject<CreateUpdateAgentDirectorate>): AxiosPromise<AgentDirectorate> => {
    return apiService.put(UPDATE_AGENT_DIRECTORATE.getParametrizedUrl(request.id), request.object);
  },
  deleteAgentDirectorate: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_AGENT_DIRECTORATE.getParametrizedUrl(request.id));
  }
};

export default api;
