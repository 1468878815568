import { Alert, Popover } from "antd";
import t from "../../../../../../app/i18n";
import { HttpStatus } from "../../../../../../common/api/http";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";
import MultiLineText from "../../../../../../common/components/views/MultiLineText";
import ComponentWithPermission from "../../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { formatLocaleCurrency } from "../../../../../../common/utils/formatUtils";
import { cssVariables, isDefined } from "../../../../../../common/utils/utils";
import { InsuranceCoverageType } from "../../../../../contract/enums";
import { CalcResultAttachments } from "../../../components/CalcResultAttachments";
import { CalcResult } from "../../../types";
import { TravelInsuranceType } from "../../enums";
import { TravelCalcResultData } from "../../types";

interface Props {
  result: CalcResult<TravelCalcResultData>;
  disabled: boolean;
  insuranceType: TravelInsuranceType;
  onGenerateContractClick: (result: CalcResult<TravelCalcResultData>) => void;
  onShowErrorsClick?: (result: CalcResult<TravelCalcResultData>) => void;
}

const TravelCalcResultItem = ({ result, disabled, insuranceType, ...props }: Props) => {
  const resultAnnualPremiumTable = result.data ? (
    <table className="prices-table-view">
      <tbody>
        <tr>
          <td>{t("calc.travel.results.annualPremium")}</td>
          <td>{formatLocaleCurrency(result.data.annualPremium)}</td>
        </tr>
      </tbody>
    </table>
  ) : undefined;

  const resultCoveragesDescription = result.data?.appliedCoverages ? (
    <div className="sub-header-info">
      {result.data.appliedCoverages
        .map(coverage => t("contract.enums.insuranceCoverageType." + coverage.type))
        .join(", ")}
    </div>
  ) : undefined;

  let resultAlert;
  if (result.data?.warnings?.length > 0) {
    resultAlert = (
      <Alert type="warning" showIcon message={<MultiLineText showDivider tokens={result.data.warnings} />} />
    );
  } else if (result.error) {
    if (result.error.status === HttpStatus.UNPROCESSABLE_ENTITY) {
      resultAlert = (
        <Alert
          type="error"
          showIcon
          message={result.error.message}
          description={<FieldViolationsView violations={result.error.violations} rootPath="calc.travel.attrs" />}
        />
      );
    } else {
      resultAlert = (
        <Alert
          type="error"
          showIcon
          message={result.error.message}
          description={<MultiLineText tokens={result.error.errors} />}
        />
      );
    }
  }

  let resultAction;
  if (result.data) {
    resultAction = (
      <ComponentWithPermission permissions={[Permission.TRAVEL_GENERATE_CONTRACT]}>
        <ActionTextIcon
          icon="check-circle"
          color="green"
          size="large"
          disabled={disabled}
          text={t("calc.travel.actions.createContract")}
          onClick={() => props.onGenerateContractClick(result)}
        />
      </ComponentWithPermission>
    );
  } else if (props.onShowErrorsClick && result.error?.status === HttpStatus.UNPROCESSABLE_ENTITY) {
    resultAction = (
      <ActionTextIcon
        icon="eye"
        color="red"
        size="large"
        text={t("calc.travel.actions.showErrors")}
        onClick={() => (props.onShowErrorsClick ? props.onShowErrorsClick(result) : undefined)}
      />
    );
  }

  const resultContent = (
    <div>
      {result.coverage && <h4 style={{ color: cssVariables.colorBlue }}>{result.coverage}</h4>}
      {resultAnnualPremiumTable}
      {resultCoveragesDescription}
      {resultAlert && <div className="margin-top-tiny">{resultAlert}</div>}
      {resultAction && <div className="margin-top-tiny">{resultAction}</div>}
    </div>
  );

  return result.error ? (
    resultContent
  ) : (
    <Popover
      placement="left"
      title={t("calc.travel.results.additionalInfo")}
      content={
        <div style={{ maxWidth: 350 }}>
          <table className="prices-table-view">
            <thead>
              <tr>
                <th>{t("calc.travel.results.coverage")}</th>
                <th>{t("calc.travel.results.coverageValue")}</th>
              </tr>
            </thead>
            <tbody>
              {result.data.appliedCoverages.map((coverage, index) => {
                switch (coverage.type) {
                  case InsuranceCoverageType.TRAVEL_INJURY:
                    return (
                      <tr key={index}>
                        <td className="nowrap">
                          {t("calc.travel.results.coverageInjuryLongTermEffects")}
                          <br />
                          {t("calc.travel.results.coverageInjuryDeath")}
                        </td>
                        <td>
                          {formatLocaleCurrency(coverage.injuryLongTermEffectsInsuranceAmount)}
                          <br />
                          {formatLocaleCurrency(coverage.injuryDeathInsuranceAmount)}
                        </td>
                      </tr>
                    );
                  case InsuranceCoverageType.TRAVEL_LIABILITY:
                    return isDefined<number>(coverage.insuranceAmount) ? (
                      <tr key={index}>
                        <td className="nowrap">{t("contract.enums.insuranceCoverageType." + coverage.type)}</td>
                        <td>{formatLocaleCurrency(coverage.insuranceAmount)}</td>
                      </tr>
                    ) : (
                      <tr key={index}>
                        <td className="nowrap">
                          {t("calc.travel.results.coverageLiabilityHealth")}
                          <br />
                          {t("calc.travel.results.coverageLiabilityProperty")}
                        </td>
                        <td>
                          {formatLocaleCurrency(coverage.liabilityHealthInsuranceAmount)}
                          <br />
                          {formatLocaleCurrency(coverage.liabilityPropertyInsuranceAmount)}
                        </td>
                      </tr>
                    );
                  default:
                    return (
                      <tr key={index}>
                        <td className="nowrap">{t("contract.enums.insuranceCoverageType." + coverage.type)}</td>
                        <td>
                          {isDefined<number>(coverage.insuranceAmount)
                            ? formatLocaleCurrency(coverage.insuranceAmount)
                            : coverage.insuranceAmountDesc}
                        </td>
                      </tr>
                    );
                }
              })}
            </tbody>
          </table>

          <table className="prices-table-view">
            <tbody>
              <tr>
                <td>
                  {t("calc.travel.enums.territorialValidity._label")}
                  {insuranceType === TravelInsuranceType.YEAR && (
                    <>
                      <br /> {t("calc.travel.results.maxTripLength")}
                    </>
                  )}
                </td>
                <td>
                  {t("calc.travel.enums.territorialValidity." + result.data.territorialValidity)}
                  {insuranceType === TravelInsuranceType.YEAR && (
                    <>
                      <br /> {result.data.maxTripLength}
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <CalcResultAttachments attachments={result.attachments} />
        </div>
      }
    >
      {resultContent}
    </Popover>
  );
};

export default TravelCalcResultItem;
