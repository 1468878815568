import { AxiosResponse } from "axios";
import { Pathname } from "history";
import { generatePath } from "react-router-dom";
import { combineReducers } from "redux";
import { replace } from "redux-first-history";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import {
  EntityIdObject,
  EntityObject,
  EntityOptionalObject,
  RootState,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../common/types";
import { initPageResult, initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { openBlobFile, removeFromArray, replaceInArray } from "../../common/utils/utils";
import { changeRunningRequestKeyAction, selectRouterLocationPathname } from "../ducks";
import { getEnumerationsActions } from "../enumerations/ducks";
import api from "./api";
import { AGENT_ROUTE_PATHS } from "./paths";
import {
  Agent,
  AgentAttachment,
  AgentBranding,
  AgentBrokerNumber,
  AgentCompetence,
  AgentCompetenceFilterPageRequest,
  AgentCompetenceFilterPageResult,
  AgentCompetenceIntervalList,
  AgentFilterExportRequest,
  AgentFilterPageRequest,
  AgentFilterPageResult,
  AgentLicense,
  AgentList,
  AgentProfilePicture,
  AgentReducerState,
  AgentSearchRequest,
  AgentSearchResult,
  AgentTree,
  AgentTreeFilterRequest,
  CreateUpdateAgent,
  CreateUpdateAgentBranding,
  CreateUpdateAgentBrokerNumber,
  CreateUpdateAgentCompetence,
  CreateUpdateAgentLicense
} from "./types";
import { sortAgentBrokerNumbers, sortAgentCompetences, sortAgentLicenses } from "./utils";

/**
 * ACTIONS - AGENT
 */
export const filterAgentsActions = createAsyncAction(
  "agent/FILTER_REQUEST",
  "agent/FILTER_SUCCESS",
  "agent/FILTER_FAILURE"
)<AgentFilterPageRequest, AgentFilterPageResult, void>();

export const downloadAgentsExportActions = createAsyncAction(
  "agent/EXPORT_REQUEST",
  "agent/EXPORT_SUCCESS",
  "agent/EXPORT_FAILURE"
)<AgentFilterExportRequest, void, void>();

export const getAgentTreeActions = createAsyncAction(
  "agent/GET_TREE_REQUEST",
  "agent/GET_TREE_SUCCESS",
  "agent/GET_TREE_FAILURE"
)<AgentTreeFilterRequest, AgentTree, void>();

export const searchAgentActions = createAsyncAction(
  "agent/SEARCH_REQUEST",
  "agent/SEARCH_SUCCESS",
  "agent/SEARCH_FAILURE"
)<AgentSearchRequest, AgentSearchResult, void>();

export const getAgentActions = createAsyncAction("agent/GET_REQUEST", "agent/GET_SUCCESS", "agent/GET_FAILURE")<
  EntityIdObject,
  Agent,
  void
>();

export const createAgentActions = createAsyncAction(
  "agent/CREATE_REQUEST",
  "agent/CREATE_SUCCESS",
  "agent/CREATE_FAILURE"
)<CreateUpdateAgent, Agent, void>();

export const getAgentPartnerIdNumberActions = createAsyncAction(
  "agent/GET_PARTNER_ID_NUMBER_REQUEST",
  "agent/GET_PARTNER_ID_NUMBER_SUCCESS",
  "agent/GET_PARTNER_ID_NUMBER_FAILURE"
)<void, string, void>();

export const updateAgentActions = createAsyncAction(
  "agent/UPDATE_REQUEST",
  "agent/UPDATE_SUCCESS",
  "agent/UPDATE_FAILURE"
)<EntityObject<CreateUpdateAgent>, Agent, void>();

export const deleteAgentActions = createAsyncAction(
  "agent/DELETE_REQUEST",
  "agent/DELETE_SUCCESS",
  "agent/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateAgentsPageAction = createAction("agent/DELETE_STATE_LIST")<void>();
export const deleteStateAgentTreeAction = createAction("agent/DELETE_STATE_TREE")<void>();
export const deleteStateAgentSearchResultAction = createAction("agent/DELETE_STATE_SEARCH_RESULT")<void>();
export const deleteStateAgentDetailAction = createAction("agent/DELETE_STATE_DETAIL")<void>();
export const deleteStateAgentPartnerIdNumberAction = createAction("agent/DELETE_STATE_PARTNER_ID_NUMBER")<void>();

/**
 * ACTIONS - AGENT PROFILE PICTURE
 */
export const downloadProfilePictureActions = createAsyncAction(
  "agent/DOWNLOAD_PROFILE_PICTURE_REQUEST",
  "agent/DOWNLOAD_PROFILE_PICTURE_SUCCESS",
  "agent/DOWNLOAD_PROFILE_PICTURE_FAILURE"
)<EntityIdObject, void, void>();

export const updateProfilePictureActions = createAsyncAction(
  "agent/UPDATE_PROFILE_PICTURE_REQUEST",
  "agent/UPDATE_PROFILE_PICTURE_SUCCESS",
  "agent/UPDATE_PROFILE_PICTURE_FAILURE"
)<EntityObject<FormData>, EntityObject<AgentProfilePicture>, void>();

export const deleteProfilePictureActions = createAsyncAction(
  "agent/DELETE_PROFILE_PICTURE_REQUEST",
  "agent/DELETE_PROFILE_PICTURE_SUCCESS",
  "agent/DELETE_PROFILE_PICTURE_FAILURE"
)<EntityIdObject, EntityOptionalObject<AgentProfilePicture>, void>();

/**
 * ACTIONS - AGENT ATTACHMENT
 */
export const uploadAgentAttachmentsActions = createAsyncAction(
  "agent-attachment/UPLOAD_REQUEST",
  "agent-attachment/UPLOAD_SUCCESS",
  "agent-attachment/UPLOAD_FAILURE"
)<EntityObject<FormData>, EntityObject<AgentAttachment[]>, void>();

export const downloadAgentAttachmentActions = createAsyncAction(
  "agent-attachment/DOWNLOAD_REQUEST",
  "agent-attachment/DOWNLOAD_SUCCESS",
  "agent-attachment/DOWNLOAD_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

export const downloadAgentAttachmentsAsZipActions = createAsyncAction(
  "agent-attachment/DOWNLOAD_ATTACHMENTS_AS_ZIP_REQUEST",
  "agent-attachment/DOWNLOAD_ATTACHMENTS_AS_ZIP_SUCCESS",
  "agent-attachment/DOWNLOAD_ATTACHMENTS_AS_ZIP_FAILURE"
)<EntityIdObject, void, void>();

export const deleteAgentAttachmentActions = createAsyncAction(
  "agent-attachment/DELETE_REQUEST",
  "agent-attachment/DELETE_SUCCESS",
  "agent-attachment/DELETE_FAILURE"
)<TwoLevelEntityIdObject, EntityIdObject, void>();

/**
 * ACTIONS - AGENT BRANDING
 */
export const createAgentBrandingActions = createAsyncAction(
  "agent-branding/CREATE_REQUEST",
  "agent-branding/CREATE_SUCCESS",
  "agent-branding/CREATE_FAILURE"
)<EntityObject<CreateUpdateAgentBranding>, AgentBranding, void>();

export const getAgentBrandingActions = createAsyncAction(
  "agent-branding/GET_REQUEST",
  "agent-branding/GET_SUCCESS",
  "agent-branding/GET_FAILURE"
)<EntityIdObject, AgentBranding, void>();

export const updateAgentBrandingActions = createAsyncAction(
  "agent-branding/UPDATE_REQUEST",
  "agent-branding/UPDATE_SUCCESS",
  "agent-branding/UPDATE_FAILURE"
)<TwoLevelEntityObject<CreateUpdateAgentBranding>, AgentBranding, void>();

export const deleteAgentBrandingActions = createAsyncAction(
  "agent-branding/DELETE_REQUEST",
  "agent-branding/DELETE_SUCCESS",
  "agent-branding/DELETE_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

/**
 * ACTIONS - AGENT COMPETENCE
 */
export const filterAgentEndingCompetencesActions = createAsyncAction(
  "agent-competence/FILTER_ENDING_REQUEST",
  "agent-competence/FILTER_ENDING_SUCCESS",
  "agent-competence/FILTER_ENDING_FAILURE"
)<AgentCompetenceFilterPageRequest, AgentCompetenceFilterPageResult, void>();

export const getAgentCompetencesActions = createAsyncAction(
  "agent-competence/GET_REQUEST",
  "agent-competence/GET_SUCCESS",
  "agent-competence/GET_FAILURE"
)<EntityIdObject, AgentCompetence[], void>();

export const createAgentCompetenceActions = createAsyncAction(
  "agent-competence/CREATE_REQUEST",
  "agent-competence/CREATE_SUCCESS",
  "agent-competence/CREATE_FAILURE"
)<EntityObject<CreateUpdateAgentCompetence>, AgentCompetence, void>();

export const updateAgentCompetenceActions = createAsyncAction(
  "agent-competence/UPDATE_REQUEST",
  "agent-competence/UPDATE_SUCCESS",
  "agent-competence/UPDATE_FAILURE"
)<TwoLevelEntityObject<CreateUpdateAgentCompetence>, AgentCompetence, void>();

export const deleteAgentCompetenceActions = createAsyncAction(
  "agent-competence/DELETE_REQUEST",
  "agent-competence/DELETE_SUCCESS",
  "agent-competence/DELETE_FAILURE"
)<TwoLevelEntityIdObject, TwoLevelEntityIdObject, void>();

export const deleteStateAgentEndingCompetencesPageAction = createAction("agent-competence/DELETE_ENDING_LIST")<void>();

/**
 * ACTIONS - AGENT LICENSE
 */
export const getAgentLicensesActions = createAsyncAction(
  "agent-license/GET_REQUEST",
  "agent-license/GET_SUCCESS",
  "agent-license/GET_FAILURE"
)<EntityIdObject, AgentLicense[], void>();

export const createAgentLicenseActions = createAsyncAction(
  "agent-license/CREATE_REQUEST",
  "agent-license/CREATE_SUCCESS",
  "agent-license/CREATE_FAILURE"
)<EntityObject<CreateUpdateAgentLicense>, AgentLicense, void>();

export const updateAgentLicenseActions = createAsyncAction(
  "agent-license/UPDATE_REQUEST",
  "agent-license/UPDATE_SUCCESS",
  "agent-license/UPDATE_FAILURE"
)<TwoLevelEntityObject<CreateUpdateAgentLicense>, AgentLicense, void>();

export const deleteAgentLicenseActions = createAsyncAction(
  "agent-license/DELETE_REQUEST",
  "agent-license/DELETE_SUCCESS",
  "agent-license/DELETE_FAILURE"
)<TwoLevelEntityIdObject, TwoLevelEntityIdObject, void>();

/**
 * ACTIONS - AGENT BROKER NUMBER
 */
export const getAgentBrokerNumbersActions = createAsyncAction(
  "agent-broker-number/GET_REQUEST",
  "agent-broker-number/GET_SUCCESS",
  "agent-broker-number/GET_FAILURE"
)<EntityIdObject, AgentBrokerNumber[], void>();

export const createAgentBrokerNumberActions = createAsyncAction(
  "agent-broker-number/CREATE_REQUEST",
  "agent-broker-number/CREATE_SUCCESS",
  "agent-broker-number/CREATE_FAILURE"
)<EntityObject<CreateUpdateAgentBrokerNumber>, AgentBrokerNumber, void>();

export const updateAgentBrokerNumberActions = createAsyncAction(
  "agent-broker-number/UPDATE_REQUEST",
  "agent-broker-number/UPDATE_SUCCESS",
  "agent-broker-number/UPDATE_FAILURE"
)<TwoLevelEntityObject<CreateUpdateAgentBrokerNumber>, AgentBrokerNumber, void>();

export const deleteAgentBrokerNumberActions = createAsyncAction(
  "agent-broker-number/DELETE_REQUEST",
  "agent-broker-number/DELETE_SUCCESS",
  "agent-broker-number/DELETE_FAILURE"
)<TwoLevelEntityIdObject, TwoLevelEntityIdObject, void>();

const actions = {
  filterAgentsActions,
  downloadAgentsExportActions,
  getAgentTreeActions,
  searchAgentActions,
  getAgentActions,
  createAgentActions,
  getAgentPartnerIdNumberActions,
  updateAgentActions,
  deleteAgentActions,
  deleteStateAgentsPageAction,
  deleteStateAgentTreeAction,
  deleteStateAgentSearchResultAction,
  deleteStateAgentDetailAction,
  deleteStateAgentPartnerIdNumberAction,
  downloadProfilePictureActions,
  updateProfilePictureActions,
  deleteProfilePictureActions,
  downloadAgentAttachmentActions,
  downloadAgentAttachmentsAsZipActions,
  uploadAgentAttachmentsActions,
  deleteAgentAttachmentActions,
  createAgentBrandingActions,
  getAgentBrandingActions,
  updateAgentBrandingActions,
  deleteAgentBrandingActions,
  filterAgentEndingCompetencesActions,
  getAgentCompetencesActions,
  createAgentCompetenceActions,
  updateAgentCompetenceActions,
  deleteAgentCompetenceActions,
  deleteStateAgentEndingCompetencesPageAction,
  getAgentLicensesActions,
  createAgentLicenseActions,
  updateAgentLicenseActions,
  deleteAgentLicenseActions,
  getAgentBrokerNumbersActions,
  createAgentBrokerNumberActions,
  updateAgentBrokerNumberActions,
  deleteAgentBrokerNumberActions
};

export type AgentAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: AgentReducerState = {
  currentPage: {
    ...initSearchPageResult<AgentList>(),
    commissionsSettingsLevelIds: [],
    agentCreatedAtDateMin: undefined,
    rootAgentId: undefined,
    onlyDirectSubordinates: false,
    includeDeactivated: true,
    includeRepresentatives: true,
    includeNonGainers: true
  },
  tree: null,
  endingCompetencesPage: {
    ...initPageResult<AgentCompetenceIntervalList>(),
    types: [],
    sectors: [],
    levels: [],
    providers: [],
    agentIds: []
  },
  searchResult: {
    keyword: undefined,
    agentType: undefined,
    data: undefined
  },
  partnerIdNumber: null,
  agentDetail: null,
  agentBranding: null,
  agentCompetences: [],
  agentLicenses: [],
  agentBrokerNumbers: []
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterAgentsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterAgentsActions.failure, deleteAgentActions.success, deleteStateAgentsPageAction],
    () => initialState.currentPage
  );

const treeReducer = createReducer(initialState.tree)
  .handleAction(getAgentTreeActions.success, (_, { payload }) => payload)
  .handleAction(
    [getAgentTreeActions.failure, deleteAgentActions.success, deleteStateAgentTreeAction],
    () => initialState.tree
  );

const endingCompetencesPageReducer = createReducer(initialState.endingCompetencesPage)
  .handleAction(filterAgentEndingCompetencesActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterAgentEndingCompetencesActions.failure, deleteStateAgentEndingCompetencesPageAction],
    () => initialState.endingCompetencesPage
  );

const searchResultReducer = createReducer(initialState.searchResult)
  .handleAction(searchAgentActions.success, (_, { payload }) => payload)
  .handleAction([searchAgentActions.failure, deleteStateAgentSearchResultAction], () => initialState.searchResult);

const partnerIdNumberReducer = createReducer(initialState.partnerIdNumber)
  .handleAction(getAgentPartnerIdNumberActions.success, (_, { payload }) => payload)
  .handleAction(
    [getAgentPartnerIdNumberActions.failure, deleteStateAgentPartnerIdNumberAction],
    () => initialState.partnerIdNumber
  );

const agentDetailReducer = createReducer(initialState.agentDetail)
  .handleAction(
    [getAgentActions.success, createAgentActions.success, updateAgentActions.success],
    (_, { payload }) => payload
  )
  .handleAction([updateProfilePictureActions.success, deleteProfilePictureActions.success], (state, { payload }) =>
    state ? { ...state, profilePicture: payload.object } : state
  )
  .handleAction(uploadAgentAttachmentsActions.success, (state, { payload }) =>
    state ? { ...state, attachments: payload.object } : state
  )
  .handleAction(deleteAgentAttachmentActions.success, (state, { payload }) =>
    state ? { ...state, attachments: removeFromArray(state.attachments, item => item.id === payload.id) } : state
  )
  .handleAction(
    [getAgentActions.failure, deleteAgentActions.success, deleteStateAgentDetailAction],
    () => initialState.agentDetail
  );

const agentBrandingReducer = createReducer(initialState.agentBranding)
  .handleAction(
    [getAgentBrandingActions.success, createAgentBrandingActions.success, updateAgentBrandingActions.success],
    (_, { payload }) => payload
  )
  .handleAction(
    [getAgentBrandingActions.failure, deleteAgentBrandingActions.success, deleteStateAgentDetailAction],
    () => initialState.agentBranding
  );

const agentCompetencesReducer = createReducer(initialState.agentCompetences)
  .handleAction(getAgentCompetencesActions.success, (_, { payload }) => sortAgentCompetences(payload))
  .handleAction(createAgentCompetenceActions.success, (state, { payload }) => sortAgentCompetences([payload, ...state]))
  .handleAction(deleteAgentCompetenceActions.success, (state, { payload }) =>
    removeFromArray(state, item => item.id === payload.id2)
  )
  .handleAction(
    [getAgentCompetencesActions.failure, deleteStateAgentDetailAction],
    () => initialState.agentCompetences
  );

const agentLicensesReducer = createReducer(initialState.agentLicenses)
  .handleAction(getAgentLicensesActions.success, (_, { payload }) => sortAgentLicenses(payload))
  .handleAction(createAgentLicenseActions.success, (state, { payload }) => sortAgentLicenses([payload, ...state]))
  .handleAction(updateAgentLicenseActions.success, (state, { payload }) =>
    sortAgentLicenses(
      replaceInArray(
        state,
        item => item.id === payload.id,
        () => payload
      )
    )
  )
  .handleAction(deleteAgentLicenseActions.success, (state, { payload }) =>
    removeFromArray(state, item => item.id === payload.id2)
  )
  .handleAction([getAgentLicensesActions.failure, deleteStateAgentDetailAction], () => initialState.agentLicenses);

const agentBrokerNumbersReducer = createReducer(initialState.agentBrokerNumbers)
  .handleAction(getAgentBrokerNumbersActions.success, (_, { payload }) => sortAgentBrokerNumbers(payload))
  .handleAction(createAgentBrokerNumberActions.success, (state, { payload }) =>
    sortAgentBrokerNumbers([payload, ...state])
  )
  .handleAction(updateAgentBrokerNumberActions.success, (state, { payload }) =>
    sortAgentBrokerNumbers(
      replaceInArray(
        state,
        item => item.id === payload.id,
        () => payload
      )
    )
  )
  .handleAction(deleteAgentBrokerNumberActions.success, (state, { payload }) =>
    removeFromArray(state, item => item.id === payload.id2)
  )
  .handleAction(
    [getAgentBrokerNumbersActions.failure, deleteStateAgentDetailAction],
    () => initialState.agentBrokerNumbers
  );

export const agentReducer = combineReducers<AgentReducerState>({
  currentPage: currentPageReducer,
  tree: treeReducer,
  endingCompetencesPage: endingCompetencesPageReducer,
  searchResult: searchResultReducer,
  partnerIdNumber: partnerIdNumberReducer,
  agentDetail: agentDetailReducer,
  agentBranding: agentBrandingReducer,
  agentCompetences: agentCompetencesReducer,
  agentLicenses: agentLicensesReducer,
  agentBrokerNumbers: agentBrokerNumbersReducer
});

/**
 * SELECTORS
 */
const selectAgent = (state: RootState): AgentReducerState => state.agent;

export const selectAgentsCurrentPage = (state: RootState): AgentFilterPageResult => selectAgent(state).currentPage;
export const selectAgentTree = (state: RootState): AgentTree | undefined => selectAgent(state).tree ?? undefined;
export const selectAgentEndingCompetencesPage = (state: RootState): AgentCompetenceFilterPageResult =>
  selectAgent(state).endingCompetencesPage;
export const selectAgentSearchResult = (state: RootState): AgentSearchResult => selectAgent(state).searchResult;
export const selectAgentPartnerIdNumber = (state: RootState): string | undefined =>
  selectAgent(state).partnerIdNumber ?? undefined;
export const selectAgentDetail = (state: RootState): Agent | undefined => selectAgent(state).agentDetail ?? undefined;
export const selectAgentBranding = (state: RootState): AgentBranding | undefined =>
  selectAgent(state).agentBranding ?? undefined;
export const selectAgentCompetences = (state: RootState): AgentCompetence[] => selectAgent(state).agentCompetences;
export const selectAgentLicenses = (state: RootState): AgentLicense[] => selectAgent(state).agentLicenses;
export const selectAgentBrokerNumbers = (state: RootState): AgentBrokerNumber[] =>
  selectAgent(state).agentBrokerNumbers;

/**
 * SAGAS - AGENT
 */
function* filterAgents({ payload }: ReturnType<typeof filterAgentsActions.request>) {
  try {
    const response: AxiosResponse<AgentFilterPageResult> = yield call(api.filterAgents, payload);
    yield put(filterAgentsActions.success(response.data));
  } catch {
    yield put(filterAgentsActions.failure());
  }
}

function* downloadAgentsExport({ payload }: ReturnType<typeof downloadAgentsExportActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadAgentsExport, payload);
    openBlobFile(response, true);
    yield put(downloadAgentsExportActions.success());
  } catch {
    yield put(downloadAgentsExportActions.failure());
  }
}

function* getAgentTree({ payload }: ReturnType<typeof getAgentTreeActions.request>) {
  try {
    const response: AxiosResponse<AgentTree> = yield call(api.getAgentTree, payload);
    yield put(getAgentTreeActions.success(response.data));
  } catch {
    yield put(getAgentTreeActions.failure());
  }
}

function* searchAgent({ payload }: ReturnType<typeof searchAgentActions.request>) {
  try {
    const response: AxiosResponse<AgentSearchResult> = yield call(api.searchAgent, payload);
    yield put(searchAgentActions.success(response.data));
  } catch {
    yield put(searchAgentActions.failure());
  }
}

function* getAgentDetail({ payload }: ReturnType<typeof getAgentActions.request>) {
  try {
    const response: AxiosResponse<Agent> = yield call(api.getAgent, payload);
    yield put(getAgentActions.success(response.data));
  } catch {
    yield put(getAgentActions.failure());
  }
}

function* createAgent({ payload }: ReturnType<typeof createAgentActions.request>) {
  try {
    const response: AxiosResponse<Agent> = yield call(api.createAgent, payload);
    yield put(createAgentActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(replace(generatePath(AGENT_ROUTE_PATHS.detail.to, { id: response.data.id })));
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createAgentActions.failure());
  }
}

function* getAgentPartnerIdNumber() {
  try {
    const response: AxiosResponse<string> = yield call(api.getAgentPartnerIdNumber);
    yield put(getAgentPartnerIdNumberActions.success(response.data));
  } catch {
    yield put(getAgentPartnerIdNumberActions.failure());
  }
}

function* updateAgent({ payload }: ReturnType<typeof updateAgentActions.request>) {
  try {
    const response: AxiosResponse<Agent> = yield call(api.updateAgent, payload);
    yield put(updateAgentActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateAgentActions.failure());
  }
}

function* deleteAgent({ payload }: ReturnType<typeof deleteAgentActions.request>) {
  try {
    yield call(api.deleteAgent, payload);
    yield put(deleteAgentActions.success());
    yield put(getEnumerationsActions.request());
    yield put(replace(AGENT_ROUTE_PATHS.list.to));
  } catch {
    yield put(deleteAgentActions.failure());
  }
}

/**
 * SAGAS - AGENT PROFILE PICTURE
 */
function* downloadProfilePicture({ payload }: ReturnType<typeof downloadProfilePictureActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadProfilePicture, payload);
    openBlobFile(response);
    yield put(downloadProfilePictureActions.success());
  } catch {
    yield put(downloadProfilePictureActions.failure());
  }
}

function* updateProfilePicture({ payload }: ReturnType<typeof updateProfilePictureActions.request>) {
  try {
    const response: AxiosResponse<AgentProfilePicture> = yield call(api.updateProfilePicture, payload);
    yield put(updateProfilePictureActions.success({ id: payload.id, object: response.data }));
  } catch {
    yield put(updateProfilePictureActions.failure());
  }
}

function* deleteProfilePicture({ payload }: ReturnType<typeof deleteProfilePictureActions.request>) {
  try {
    yield call(api.deleteProfilePicture, payload);
    yield put(deleteProfilePictureActions.success({ id: payload.id, object: undefined }));
  } catch {
    yield put(deleteProfilePictureActions.failure());
  }
}

/**
 * SAGAS - AGENT ATTACHMENT
 */
function* uploadAgentAttachments({ payload }: ReturnType<typeof uploadAgentAttachmentsActions.request>) {
  try {
    const response: AxiosResponse<AgentAttachment[]> = yield call(api.uploadAgentAttachments, payload);
    yield put(uploadAgentAttachmentsActions.success({ id: payload.id, object: response.data }));
  } catch {
    yield put(uploadAgentAttachmentsActions.failure());
  }
}

function* downloadAgentAttachment({ payload }: ReturnType<typeof downloadAgentAttachmentActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadAgentAttachment, payload);
    openBlobFile(response);
    yield put(downloadAgentAttachmentActions.success());
  } catch {
    yield put(downloadAgentAttachmentActions.failure());
  }
}

function* downloadAgentAttachmentsAsZip({ payload }: ReturnType<typeof downloadAgentAttachmentsAsZipActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadAgentAttachmentsAsZip, payload);
    openBlobFile(response, true);
    yield put(downloadAgentAttachmentsAsZipActions.success());
  } catch {
    yield put(downloadAgentAttachmentsAsZipActions.failure());
  }
}

function* deleteAgentAttachment({ payload }: ReturnType<typeof deleteAgentAttachmentActions.request>) {
  try {
    yield call(api.deleteAgentAttachment, payload);
    yield put(deleteAgentAttachmentActions.success({ id: payload.id2 }));
  } catch {
    yield put(deleteAgentAttachmentActions.failure());
  }
}

/**
 * SAGAS - AGENT BRANDING
 */
function* getAgentBranding({ payload }: ReturnType<typeof getAgentBrandingActions.request>) {
  try {
    const response: AxiosResponse<AgentBranding> = yield call(api.getAgentBranding, payload);
    yield put(getAgentBrandingActions.success(response.data));
  } catch {
    yield put(getAgentBrandingActions.failure());
  }
}

function* createAgentBranding({ payload }: ReturnType<typeof createAgentBrandingActions.request>) {
  try {
    const response: AxiosResponse<AgentBranding> = yield call(api.createAgentBranding, payload);
    yield put(createAgentBrandingActions.success(response.data));
    yield put(getEnumerationsActions.request());
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createAgentBrandingActions.failure());
  }
}

function* updateAgentBranding({ payload }: ReturnType<typeof updateAgentBrandingActions.request>) {
  try {
    const response: AxiosResponse<AgentBranding> = yield call(api.updateAgentBranding, payload);
    yield put(updateAgentBrandingActions.success(response.data));
    yield put(getEnumerationsActions.request());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateAgentBrandingActions.failure());
  }
}

function* deleteAgentBranding({ payload }: ReturnType<typeof deleteAgentBrandingActions.request>) {
  try {
    yield call(api.deleteAgentBranding, payload);
    yield put(deleteAgentBrandingActions.success());
    yield put(getEnumerationsActions.request());
    messageUtils.itemDeletedNotification();
  } catch {
    yield put(deleteAgentBrandingActions.failure());
  }
}

/**
 * SAGAS - AGENT COMPETENCE
 */
function* filterAgentEndingCompetences({ payload }: ReturnType<typeof filterAgentEndingCompetencesActions.request>) {
  try {
    const response: AxiosResponse<AgentCompetenceFilterPageResult> = yield call(
      api.filterAgentEndingCompetences,
      payload
    );
    yield put(filterAgentEndingCompetencesActions.success(response.data));
  } catch {
    yield put(filterAgentEndingCompetencesActions.failure());
  }
}

function* getAgentCompetences({ payload }: ReturnType<typeof getAgentCompetencesActions.request>) {
  try {
    const response: AxiosResponse<AgentCompetence[]> = yield call(api.getAgentCompetences, payload);
    yield put(getAgentCompetencesActions.success(response.data));
  } catch {
    yield put(getAgentCompetencesActions.failure());
  }
}

function* createAgentCompetence({ payload }: ReturnType<typeof createAgentCompetenceActions.request>) {
  try {
    const response: AxiosResponse<AgentCompetence> = yield call(api.createAgentCompetence, payload);
    yield put(createAgentCompetenceActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createAgentCompetenceActions.failure());
  }
}

function* updateAgentCompetence({ payload }: ReturnType<typeof updateAgentCompetenceActions.request>) {
  try {
    const response: AxiosResponse<AgentCompetence> = yield call(api.updateAgentCompetence, payload);
    yield put(updateAgentCompetenceActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();

    const location: Pathname = yield select(selectRouterLocationPathname);
    if (location === generatePath(AGENT_ROUTE_PATHS.endingCompetences.to)) {
      const page: AgentCompetenceFilterPageResult = yield select(selectAgentEndingCompetencesPage);
      yield put(
        filterAgentEndingCompetencesActions.request({
          pageIndex: page.pageIndex,
          pageSize: page.pageSize,
          types: page.types,
          sectors: page.sectors,
          levels: page.levels,
          providers: page.providers,
          agentIds: page.agentIds
        })
      );
    } else {
      const competences: AgentCompetence[] = yield select(selectAgentCompetences);
      yield put(
        getAgentCompetencesActions.success(
          replaceInArray(
            competences,
            item => item.id === response.data.id,
            () => response.data
          )
        )
      );
    }
  } catch {
    yield put(updateAgentCompetenceActions.failure());
  }
}

function* deleteAgentCompetence({ payload }: ReturnType<typeof deleteAgentCompetenceActions.request>) {
  try {
    yield call(api.deleteAgentCompetence, payload);
    yield put(deleteAgentCompetenceActions.success(payload));
    messageUtils.itemDeletedNotification();
  } catch {
    yield put(deleteAgentCompetenceActions.failure());
  }
}

/**
 * SAGAS - AGENT LICENSE
 */
function* getAgentLicenses({ payload }: ReturnType<typeof getAgentLicensesActions.request>) {
  try {
    const response: AxiosResponse<AgentLicense[]> = yield call(api.getAgentLicenses, payload);
    yield put(getAgentLicensesActions.success(response.data));
  } catch {
    yield put(getAgentLicensesActions.failure());
  }
}

function* createAgentLicense({ payload }: ReturnType<typeof createAgentLicenseActions.request>) {
  try {
    const response: AxiosResponse<AgentLicense> = yield call(api.createAgentLicense, payload);
    yield put(createAgentLicenseActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createAgentLicenseActions.failure());
  }
}

function* updateAgentLicense({ payload }: ReturnType<typeof updateAgentLicenseActions.request>) {
  try {
    const response: AxiosResponse<AgentLicense> = yield call(api.updateAgentLicense, payload);
    yield put(updateAgentLicenseActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateAgentLicenseActions.failure());
  }
}

function* deleteAgentLicense({ payload }: ReturnType<typeof deleteAgentLicenseActions.request>) {
  try {
    yield call(api.deleteAgentLicense, payload);
    yield put(deleteAgentLicenseActions.success(payload));
    messageUtils.itemDeletedNotification();
  } catch {
    yield put(deleteAgentLicenseActions.failure());
  }
}

/**
 * SAGAS - AGENT BROKER NUMBER
 */
function* getAgentBrokerNumbers({ payload }: ReturnType<typeof getAgentBrokerNumbersActions.request>) {
  try {
    const response: AxiosResponse<AgentBrokerNumber[]> = yield call(api.getAgentBrokerNumbers, payload);
    yield put(getAgentBrokerNumbersActions.success(response.data));
  } catch {
    yield put(getAgentBrokerNumbersActions.failure());
  }
}

function* createAgentBrokerNumber({ payload }: ReturnType<typeof createAgentBrokerNumberActions.request>) {
  try {
    const response: AxiosResponse<AgentBrokerNumber> = yield call(api.createAgentBrokerNumber, payload);
    yield put(createAgentBrokerNumberActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createAgentBrokerNumberActions.failure());
  }
}

function* updateAgentBrokerNumber({ payload }: ReturnType<typeof updateAgentBrokerNumberActions.request>) {
  try {
    const response: AxiosResponse<AgentBrokerNumber> = yield call(api.updateAgentBrokerNumber, payload);
    yield put(updateAgentBrokerNumberActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateAgentBrokerNumberActions.failure());
  }
}

function* deleteAgentBrokerNumber({ payload }: ReturnType<typeof deleteAgentBrokerNumberActions.request>) {
  try {
    yield call(api.deleteAgentBrokerNumber, payload);
    yield put(deleteAgentBrokerNumberActions.success(payload));
    messageUtils.itemDeletedNotification();
  } catch {
    yield put(deleteAgentBrokerNumberActions.failure());
  }
}

export function* agentSaga() {
  yield takeLatest(filterAgentsActions.request, filterAgents);
  yield takeLatest(downloadAgentsExportActions.request, downloadAgentsExport);
  yield takeLatest(getAgentTreeActions.request, getAgentTree);
  yield takeLatest(searchAgentActions.request, searchAgent);
  yield takeLatest(getAgentActions.request, getAgentDetail);
  yield takeLatest(createAgentActions.request, createAgent);
  yield takeLatest(getAgentPartnerIdNumberActions.request, getAgentPartnerIdNumber);
  yield takeLatest(updateAgentActions.request, updateAgent);
  yield takeLatest(deleteAgentActions.request, deleteAgent);

  yield takeLatest(downloadProfilePictureActions.request, downloadProfilePicture);
  yield takeLatest(updateProfilePictureActions.request, updateProfilePicture);
  yield takeLatest(deleteProfilePictureActions.request, deleteProfilePicture);

  yield takeLatest(uploadAgentAttachmentsActions.request, uploadAgentAttachments);
  yield takeLatest(downloadAgentAttachmentActions.request, downloadAgentAttachment);
  yield takeLatest(downloadAgentAttachmentsAsZipActions.request, downloadAgentAttachmentsAsZip);
  yield takeLatest(deleteAgentAttachmentActions.request, deleteAgentAttachment);

  yield takeLatest(getAgentBrandingActions.request, getAgentBranding);
  yield takeLatest(createAgentBrandingActions.request, createAgentBranding);
  yield takeLatest(updateAgentBrandingActions.request, updateAgentBranding);
  yield takeLatest(deleteAgentBrandingActions.request, deleteAgentBranding);

  yield takeLatest(filterAgentEndingCompetencesActions.request, filterAgentEndingCompetences);
  yield takeLatest(getAgentCompetencesActions.request, getAgentCompetences);
  yield takeLatest(createAgentCompetenceActions.request, createAgentCompetence);
  yield takeLatest(updateAgentCompetenceActions.request, updateAgentCompetence);
  yield takeLatest(deleteAgentCompetenceActions.request, deleteAgentCompetence);

  yield takeLatest(getAgentLicensesActions.request, getAgentLicenses);
  yield takeLatest(createAgentLicenseActions.request, createAgentLicense);
  yield takeLatest(updateAgentLicenseActions.request, updateAgentLicense);
  yield takeLatest(deleteAgentLicenseActions.request, deleteAgentLicense);

  yield takeLatest(getAgentBrokerNumbersActions.request, getAgentBrokerNumbers);
  yield takeLatest(createAgentBrokerNumberActions.request, createAgentBrokerNumber);
  yield takeLatest(updateAgentBrokerNumberActions.request, updateAgentBrokerNumber);
  yield takeLatest(deleteAgentBrokerNumberActions.request, deleteAgentBrokerNumber);
}
