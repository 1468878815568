import { Divider, Popconfirm, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteAgentDirectorate, useFilterAgentDirectorates } from "../../../../../common/api/queries";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { useFilterParams } from "../../../../../common/utils/hooksUtils";
import { URLQuery } from "../../../../../common/utils/queryUtils";
import { numberOrZero, tableStandardProps } from "../../../../../common/utils/utils";
import type { UUID } from "../../../../../typings/global";
import type { AgentDirectorate } from "../../../types";

type Props = {
  onChange: (agentDirectorate: AgentDirectorate) => void;
};

export const AgentDirectorateTableView = ({ onChange }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const searchParams = useFilterParams();

  const { data, isLoading } = useFilterAgentDirectorates(searchParams);
  const deleteAgentDirectorate = useDeleteAgentDirectorate();

  const handleTableChange = (pagination: TablePaginationConfig) => {
    navigate(
      {
        search: URLQuery.appendSearchParamsToString({
          pageIndex: pagination.current ? pagination.current - 1 : undefined
        })
      },
      { replace: true }
    );
  };

  const handleDeleteAgentDirectorate = (id: UUID) => {
    deleteAgentDirectorate.mutate(id);
  };

  const columns: ColumnsType<AgentDirectorate> = [
    {
      key: "name",
      title: t("agent.directorates.attrs.code"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.code}</Ellipsis>
    },
    {
      key: "name",
      title: t("agent.directorates.attrs.name"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onChange(record)} />
          <Divider type="vertical" />

          <Popconfirm
            title={t("agent.directorates.helpers.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => handleDeleteAgentDirectorate(record.id)}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Table
      {...tableStandardProps()}
      loading={isLoading}
      columns={columns}
      dataSource={data?.pageData}
      pagination={{
        ...data,
        current: numberOrZero(data?.pageIndex) + 1,
        pageSize: data?.pageSize,
        total: data?.totalElementsCount
      }}
      onChange={pagination => handleTableChange(pagination)}
    />
  );
};
