import t from "../../app/i18n";
import { Permission } from "../../common/security/authorization/enums";
import { AFFILIATE_PARTNER_ROUTE_PATHS } from "../affiliate/paths";
import { AGENT_ROUTE_PATHS } from "../agent/paths";
import { CALC_BLACKLIST_ROUTE_PATHS } from "../calculator/blacklist/paths";
import { CALC_ROUTE_PATHS } from "../calculator/paths";
import { ADMIN_CALC_ROUTE_PATHS } from "../calculator/settings/paths";
import { CLIENT_ROUTE_PATHS } from "../client/paths";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../commissions/batch/paths";
import { COMMISSIONS_LEVEL_ROUTE_PATHS } from "../commissions/level/paths";
import { POSTPONED_COMMISSIONS_ROUTE_PATHS } from "../commissions/postponed/paths";
import { COMPLICITY_ROUTE_PATHS } from "../complicity/paths";
import { CONTRACT_ROUTE_PATHS } from "../contract/paths";
import { CONTRACT_UPDATE_ROUTE_PATHS } from "../contractupdate/paths";
import { COVERAGE_LIMIT_ROUTE_PATHS } from "../coveragelimit/paths";
import { DASHBOARD_ROUTE_PATHS } from "../dashboard/paths";
import { DOCUMENT_NODE_ROUTE_PATHS } from "../documents/paths";
import { CONFIG_PROPERTY_ROUTE_PATHS } from "../dynamicconfig/paths";
import { FINANCIAL_MEDIATION_ROUTE_PATHS } from "../financialmediation/paths";
import { INSTITUTION_ROUTE_PATHS } from "../institution/paths";
import { JOB_ROUTE_PATHS } from "../jobs/paths";
import { LIFE_INSURENCE_TARIFF_ROUTE_PATHS } from "../lifeinsurancetariff/paths";
import { NOTIFICATION_ROUTE_PATHS } from "../notifications/paths";
import { PARTNER_ROUTE_PATHS } from "../partner/paths";
import { PRODUCT_ROUTE_PATHS } from "../product/paths";
import { ADMIN_USER_ROUTE_PATHS, CURRENT_USER_ROUTE_PATHS } from "../user/paths";
import { VEHICLE_BRAND_ROUTE_PATHS } from "../vehicle/brand/paths";
import { VEHICLE_ENUM_ROUTE_PATHS } from "../vehicle/paths";
import { NavigationGlobalSearch } from "./types";

export const COMMISSIONS_PROFILE_TABS = {
  OUTPUTS: "outputs",
  BAIL_ACCOUNT: "bail-account",
  DEBT_ACCOUNT: "debt-account"
};

export const USER_PROFILE_TABS = {
  USER: "user",
  ACCOUNT: "account",
  ATTACHMENTS: "attachments",
  COMPETENCES: "competences",
  LICENSES: "licenses",
  BROKER_NUMBERS: "broker-numbers",
  NOTIFICATION_SETTINGS: "notification-settings"
};

export const NavigationGlobalSearchMap: NavigationGlobalSearch[] = [
  {
    keyword: t("globalSearch.keyword.dashboard"),
    path: DASHBOARD_ROUTE_PATHS.homepage.to
  },
  {
    keyword: t("globalSearch.keyword.documents"),
    path: DOCUMENT_NODE_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.profile"),
    path: CURRENT_USER_ROUTE_PATHS.profile.to
  },
  {
    keyword: t("globalSearch.keyword.profileAttachment"),
    path: CURRENT_USER_ROUTE_PATHS.profile.to + "?tab=" + USER_PROFILE_TABS.ATTACHMENTS,
    config: {
      title: t("user.titles.agentAttachments"),
      permissions: []
    }
  },
  {
    keyword: t("globalSearch.keyword.profileCompetence"),
    path: CURRENT_USER_ROUTE_PATHS.profile.to + "?tab=" + USER_PROFILE_TABS.COMPETENCES,
    config: {
      title: t("user.titles.competences"),
      permissions: [Permission.PROFILE_COMPETENCE]
    }
  },
  {
    keyword: t("globalSearch.keyword.profileLicense"),
    path: CURRENT_USER_ROUTE_PATHS.profile.to + "?tab=" + USER_PROFILE_TABS.LICENSES,
    config: {
      title: t("user.titles.licenses"),
      permissions: [Permission.PROFILE_LICENSE]
    }
  },
  {
    keyword: t("globalSearch.keyword.profileBrokerNumber"),
    path: CURRENT_USER_ROUTE_PATHS.profile.to + "?tab=" + USER_PROFILE_TABS.BROKER_NUMBERS,
    config: {
      title: t("user.titles.brokerNumbers"),
      permissions: [Permission.PROFILE_BROKER_NUMBER]
    }
  },
  {
    keyword: t("globalSearch.keyword.profileNotificationSettings"),
    path: CURRENT_USER_ROUTE_PATHS.profile.to + "?tab=" + USER_PROFILE_TABS.NOTIFICATION_SETTINGS,
    config: {
      title: t("user.titles.notificationSettings"),
      permissions: []
    }
  },
  {
    keyword: t("globalSearch.keyword.profileCommissions"),
    path: CURRENT_USER_ROUTE_PATHS.commissions.to
  },
  {
    keyword: t("globalSearch.keyword.profileBailAccount"),
    path: CURRENT_USER_ROUTE_PATHS.commissions.to + "?tab=" + COMMISSIONS_PROFILE_TABS.BAIL_ACCOUNT,
    config: {
      title: t("commissions.bailAccount.titles.page"),
      permissions: [Permission.PROFILE_COMMISSIONS]
    }
  },
  {
    keyword: t("globalSearch.keyword.profileDebtAccount"),
    path: CURRENT_USER_ROUTE_PATHS.commissions.to + "?tab=" + COMMISSIONS_PROFILE_TABS.DEBT_ACCOUNT,
    config: {
      title: t("commissions.debtAccount.titles.page"),
      permissions: [Permission.PROFILE_COMMISSIONS]
    }
  },
  {
    keyword: t("globalSearch.keyword.notification"),
    path: NOTIFICATION_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.calcVehicle"),
    path: CALC_ROUTE_PATHS.vehicle.to
  },
  {
    keyword: t("globalSearch.keyword.calcRealty"),
    path: CALC_ROUTE_PATHS.realty.to
  },
  {
    keyword: t("globalSearch.keyword.calcTravel"),
    path: CALC_ROUTE_PATHS.travel.to
  },
  {
    keyword: t("globalSearch.keyword.calcDraftVehicle"),
    path: CALC_ROUTE_PATHS.vehicleDraft.to
  },
  {
    keyword: t("globalSearch.keyword.calcDraftRealty"),
    path: CALC_ROUTE_PATHS.realtyDraft.to
  },
  {
    keyword: t("globalSearch.keyword.calcDraftTravel"),
    path: CALC_ROUTE_PATHS.travelDraft.to
  },
  {
    keyword: t("globalSearch.keyword.calcRecords"),
    path: CALC_ROUTE_PATHS.records.to
  },
  {
    keyword: t("globalSearch.keyword.partner"),
    path: PARTNER_ROUTE_PATHS.configs.to
  },
  {
    keyword: t("globalSearch.keyword.clientList"),
    path: CLIENT_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.clientCreate"),
    path: CLIENT_ROUTE_PATHS.create.to
  },
  {
    keyword: t("globalSearch.keyword.contractList"),
    path: CONTRACT_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.contractCreate"),
    path: CONTRACT_ROUTE_PATHS.create.to
  },
  {
    keyword: t("globalSearch.keyword.contractPredictedCommissions"),
    path: CONTRACT_ROUTE_PATHS.predictedCommissions.to
  },
  {
    keyword: t("globalSearch.keyword.contractAnniversaryDates"),
    path: CONTRACT_ROUTE_PATHS.anniversaryDate.to
  },
  {
    keyword: t("globalSearch.keyword.contractFixationAnniversaryDates"),
    path: CONTRACT_ROUTE_PATHS.fixationAnniversaryDate.to
  },
  {
    keyword: t("globalSearch.keyword.contractUnpaid"),
    path: CONTRACT_ROUTE_PATHS.unpaid.to
  },
  {
    keyword: t("globalSearch.keyword.contractForms"),
    path: CONTRACT_ROUTE_PATHS.forms.to
  },
  {
    keyword: t("globalSearch.keyword.financialMediationCreate"),
    path: FINANCIAL_MEDIATION_ROUTE_PATHS.create.to
  },
  {
    keyword: t("globalSearch.keyword.agentList"),
    path: AGENT_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.agentTree"),
    path: AGENT_ROUTE_PATHS.tree.to
  },
  {
    keyword: t("globalSearch.keyword.agentCreate"),
    path: AGENT_ROUTE_PATHS.create.to
  },
  {
    keyword: t("globalSearch.keyword.agentEndingCompetences"),
    path: AGENT_ROUTE_PATHS.endingCompetences.to
  },
  {
    keyword: t("globalSearch.keyword.agentPointsReport"),
    path: AGENT_ROUTE_PATHS.pointsReport.to
  },
  {
    keyword: t("globalSearch.keyword.agentBailAccountsReport"),
    path: AGENT_ROUTE_PATHS.bailAccountsReport.to
  },
  {
    keyword: t("globalSearch.keyword.agentDebtAccountsReport"),
    path: AGENT_ROUTE_PATHS.debtAccountsReport.to
  },
  {
    keyword: t("globalSearch.keyword.commissionsBatch"),
    path: COMMISSIONS_BATCH_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.postponedCommissions"),
    path: POSTPONED_COMMISSIONS_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.commissionsLevel"),
    path: COMMISSIONS_LEVEL_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.user"),
    path: ADMIN_USER_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.userCreate"),
    path: ADMIN_USER_ROUTE_PATHS.create.to
  },
  {
    keyword: t("globalSearch.keyword.institutionList"),
    path: INSTITUTION_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.institutionCreate"),
    path: INSTITUTION_ROUTE_PATHS.create.to
  },
  {
    keyword: t("globalSearch.keyword.product"),
    path: PRODUCT_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.affiliatePartner"),
    path: AFFILIATE_PARTNER_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.agentDirectorates"),
    path: AGENT_ROUTE_PATHS.directorates.to
  },
  {
    keyword: t("globalSearch.keyword.calcAdmin"),
    path: ADMIN_CALC_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.calcAdminCreate"),
    path: ADMIN_CALC_ROUTE_PATHS.create.to
  },
  {
    keyword: t("globalSearch.keyword.contractUpdate"),
    path: CONTRACT_UPDATE_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.lifeInsuranceTariff"),
    path: LIFE_INSURENCE_TARIFF_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.coverageLimit"),
    path: COVERAGE_LIMIT_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.complicity"),
    path: COMPLICITY_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.vehicleBrand"),
    path: VEHICLE_BRAND_ROUTE_PATHS.brands.to
  },
  {
    keyword: t("globalSearch.keyword.vehicleEnum"),
    path: VEHICLE_ENUM_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.job"),
    path: JOB_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.calcBlacklist"),
    path: CALC_BLACKLIST_ROUTE_PATHS.list.to
  },
  {
    keyword: t("globalSearch.keyword.configProperty"),
    path: CONFIG_PROPERTY_ROUTE_PATHS.list.to
  }
];
