import cronParser from "cron-parser";
import dayjs, { Dayjs } from "dayjs";
import { filesize } from "filesize";
import ibanUtils from "iban";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import t, { DEFAULT_LOCALE } from "../../app/i18n";
import { TopAgentType } from "../../modules/agent/enums";
import type { AgentBase, AgentDirectorateBase } from "../../modules/agent/types";
import type { PlaceOfInsurance } from "../../modules/calculator/calcs/realty/types";
import type { ClientBase } from "../../modules/client/types";
import type { CommissionsSettingsLevelBase } from "../../modules/commissions/level/types";
import type { CoverageLimitBase } from "../../modules/coveragelimit/types";
import type { LifeInsuranceTariffBase } from "../../modules/lifeinsurancetariff/types";
import type { ProductBase } from "../../modules/product/types";
import type { Address } from "../modules/types";
import { ALL_WHITE_SPACES_PATTERN, insertToString, isDefinedValue } from "./utils";

export const DATE_REGEX = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
export const DATE_TIME_REGEX = new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?$");

export const formatIban = (iban: string): string => ibanUtils.printFormat(iban, " ");

export const formatPhoneNumber = (phone: string): string =>
  isValidPhoneNumber(phone, "SK") ? parsePhoneNumber(phone, "SK").formatInternational() : phone;

export const formatLocalePhoneNumber = (phone: string): string =>
  isValidPhoneNumber(phone, "SK") ? parsePhoneNumber(phone, "SK").formatNational() : phone;

export const formatLocaleNumber = (input?: number): string | undefined =>
  input ? Intl.NumberFormat(DEFAULT_LOCALE).format(input) : undefined;

export const formatLocaleNumberWithNullAsZero = (input: number): string =>
  Intl.NumberFormat(DEFAULT_LOCALE).format(input || 0);

export const formatLocaleCurrency = (input?: number): string | undefined => {
  return isDefinedValue(input)
    ? Intl.NumberFormat(DEFAULT_LOCALE, {
        style: "currency",
        currency: "EUR"
      }).format(input as number)
    : undefined;
};

export const formatLocaleCurrencyWithNullAsZero = (input?: number): string => {
  return Intl.NumberFormat(DEFAULT_LOCALE, { style: "currency", currency: "EUR" }).format(input || 0);
};

export const formatIntegerLocaleCurrency = (input: number): string | undefined => {
  return isDefinedValue(input)
    ? Intl.NumberFormat(DEFAULT_LOCALE, {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(input)
    : undefined;
};

export const formatIntegerLocaleCurrencyWithNullAsZero = (input: number): string => {
  return Intl.NumberFormat(DEFAULT_LOCALE, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(input || 0);
};

export const formatLocaleCurrencyWithMillionLabel = (input: number): string => {
  return (
    Intl.NumberFormat(DEFAULT_LOCALE, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(input) + " mil €"
  );
};

export const formatLocalePercentageNumber = (input: number, fractionDigits: number = 2): string | undefined => {
  return isDefinedValue(input)
    ? Intl.NumberFormat(DEFAULT_LOCALE, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
      }).format(input) + " %"
    : undefined;
};

export const formatLocalePercentageNumberWithNullAsZero = (input: number, fractionDigits: number = 2): string => {
  return (
    Intl.NumberFormat(DEFAULT_LOCALE, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    }).format(input || 0) + " %"
  );
};

export const formatLocaleNettoPoints = (input: number): string | undefined =>
  isDefinedValue(input)
    ? Intl.NumberFormat(DEFAULT_LOCALE, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(input) + " PB"
    : undefined;

export const formatLocaleNettoPointsWithNullAsZero = (input?: number): string =>
  Intl.NumberFormat(DEFAULT_LOCALE, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(input || 0) + " PB";

export const formatLocaleBruttoPoints = (input: number): string | undefined =>
  isDefinedValue(input)
    ? Intl.NumberFormat(DEFAULT_LOCALE, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(input) + " BB"
    : undefined;

export const formatLocaleBruttoPointsWithNullAsZero = (input?: number): string =>
  Intl.NumberFormat(DEFAULT_LOCALE, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(input || 0) + " BB";

export const formatLocaleDate = (input?: string | Dayjs): string | undefined => {
  return input ? dayjs(input).format("L") : undefined;
};

export const formatLocaleDateTime = (input?: string | Dayjs): string | undefined => {
  return input ? dayjs(input).format("L LTS") : undefined;
};

export const formatFileSize = (size: number): string => filesize(size, { locale: DEFAULT_LOCALE }) as string;

export const formatLicensePlate = (input: string): string => {
  if (input) {
    input = input.replace(ALL_WHITE_SPACES_PATTERN, "");
    input = input.length > 2 ? insertToString(input, " ", 2) : input;
    input = input.length > 6 ? insertToString(input, " ", 6) : input;
    return input.toUpperCase();
  }
  return input;
};

export const formatAddress = (address?: Address, showCountry: boolean = true): string | undefined => {
  return address
    ? (address.street && address.street.length > 0 ? address.street : address.city) +
        (address.descriptiveNumber && address.descriptiveNumber.length > 0
          ? ` ${address.descriptiveNumber}` +
            (address.orientationNumber && address.orientationNumber.length > 0 ? `/${address.orientationNumber}` : "")
          : ` ${address.orientationNumber}`) +
        `, ${address.zipCode} ${address.city}` +
        (showCountry ? `, ${address.country}` : "")
    : undefined;
};

export const formatPlaceOfInsurance = (place: PlaceOfInsurance, showCountry?: boolean): string | undefined => {
  return place
    ? (place.street && place.street.length > 0 ? place.street : place.city) +
        (place.descriptiveNumber && place.descriptiveNumber.length > 0
          ? ` ${place.descriptiveNumber}` +
            (place.orientationNumber && place.orientationNumber.length > 0 ? `/${place.orientationNumber}` : "")
          : place.orientationNumber && place.orientationNumber.length > 0
            ? ` ${place.orientationNumber}`
            : "") +
        (place.parcelNumber1
          ? place.parcelNumber2
            ? ` (${t("address.attrs.parcelNumber")} ${place.parcelNumber1}, ${place.parcelNumber2})`
            : ` (${t("address.attrs.parcelNumber")} ${place.parcelNumber1})`
          : "") +
        `, ${place.zipCode} ${place.city}` +
        (place.cityDistrict ? ` - ${place.cityDistrict}` : "") +
        (showCountry ? `, ${place.country}` : "")
    : undefined;
};

export const formatAggregatedName = (entity: {
  academicDegree?: string;
  firstName?: string;
  lastName?: string;
  academicDegreeAfter?: string;
}): string => {
  let aggregatedName = "";
  if (entity.academicDegree) {
    aggregatedName += `${entity.academicDegree} `;
  }

  aggregatedName += `${entity.firstName || ""} ${entity.lastName || ""}`;

  if (entity.academicDegreeAfter) {
    aggregatedName += `, ${entity.academicDegreeAfter}`;
  }

  return aggregatedName;
};

export const formatAgentStructureIdNumber = (agent: AgentBase): string | undefined => {
  return agent?.structureIdNumber ? agent.structureIdNumber.toString().padStart(3, "0") : undefined;
};

export const formatAgentShortSystemIdNumber = (agent?: AgentBase): string | undefined => {
  return agent ? agent.systemIdNumber?.toString().padStart(5, "0") : undefined;
};

export const formatAgentSystemIdNumber = (agent?: AgentBase): string | undefined => {
  if (agent) {
    const structureId = formatAgentStructureIdNumber(agent);
    const systemId = formatAgentShortSystemIdNumber(agent);
    return structureId ? structureId + systemId : systemId;
  }

  return undefined;
};

export const formatAgentIdNumber = (agent?: AgentBase): string | undefined => {
  return agent
    ? agent.topAgentType === TopAgentType.PLUS
      ? agent.partnerIdNumber
      : formatAgentSystemIdNumber(agent)
    : undefined;
};

export const formatAgentName = (agent?: AgentBase, showDeactivatedLabel: boolean = true): string | undefined => {
  if (agent) {
    const additionalInfo = [agent.tipper ? "T" : undefined, formatAgentIdNumber(agent)]
      .filter(info => !!info)
      .join(" | ");
    return (
      agent.displayName +
      (additionalInfo ? ` (${additionalInfo})` : "") +
      (agent.deactivated && showDeactivatedLabel ? ` | ${t("agent.helpers.deactivatedAgentShort")}` : "")
    );
  }

  return undefined;
};

export const formatAgentAggregatedName = (agent?: AgentBase): string | undefined => {
  if (agent) {
    const additionalInfo = [formatAgentIdNumber(agent), agent.tipper ? "T" : undefined]
      .filter(info => !!info)
      .join(" | ");
    return agent.aggregatedName + (additionalInfo ? ` (${additionalInfo})` : "");
  }
  return undefined;
};

export const formatClientName = (client?: ClientBase): string | undefined => {
  return client ? `${client.aggregatedName} (${client.identifier})` : undefined;
};

export const formatProductName = (product: ProductBase): string | undefined => {
  return product
    ? product.deactivated
      ? `${product.name} (${t("product.helpers.deactivated")})`
      : product.name
    : undefined;
};

export const formatCoverageLimit = (coverageLimit?: CoverageLimitBase): string | undefined => {
  return coverageLimit
    ? formatLocaleCurrencyWithMillionLabel(coverageLimit.health) +
        " / " +
        formatLocaleCurrencyWithMillionLabel(coverageLimit.property)
    : undefined;
};

export const formatCoverageLimitValues = (health: number, property: number): string => {
  return formatLocaleCurrencyWithMillionLabel(health) + " / " + formatLocaleCurrencyWithMillionLabel(property);
};

export const formatLifeInsuranceTariff = (tariff: LifeInsuranceTariffBase): string | undefined =>
  tariff ? `${tariff.code} - ${tariff.name}` : undefined;

export const formatCommissionsLevelName = (level: CommissionsSettingsLevelBase): string | undefined =>
  level ? level.code + " - " + level.name : undefined;

export const formatCron = (value: string, nextDatesCount: number = 1): string | undefined => {
  if (value) {
    try {
      const expression = cronParser.parseExpression(value, { tz: "Europe/Bratislava" });
      const results = [];
      for (let i = 0; i < nextDatesCount; i++) {
        if (expression.hasNext()) {
          results.push(formatLocaleDateTime(expression.next().toISOString()));
        }
      }
      return results.join(" | ");
    } catch {
      return undefined;
    }
  }
  return undefined;
};

export const formatAgentDirectorateName = (directorate: AgentDirectorateBase) => {
  return `${directorate.code} - ${directorate.name}`;
};
