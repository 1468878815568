import { Card } from "antd";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import t from "../../../app/i18n";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { RootState } from "../../../common/types";
import { AgentForm } from "../components/forms/agent/AgentForm";
import {
  createAgentActions,
  deleteStateAgentPartnerIdNumberAction,
  getAgentPartnerIdNumberActions,
  selectAgentPartnerIdNumber
} from "../ducks";
import { useAgentSearch } from "../utils";

export const AgentCreateContainer = () => {
  const agentPartnerIdNumber = useSelector<RootState, string | undefined>(selectAgentPartnerIdNumber);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          createAgent: createAgentActions.request,
          getAgentPartnerIdNumber: getAgentPartnerIdNumberActions.request,
          deleteStateAgentPartnerIdNumber: deleteStateAgentPartnerIdNumberAction
        },
        dispatch
      ),
    [dispatch]
  );

  const agentSearch = useAgentSearch();

  useEffect(() => {
    actions.getAgentPartnerIdNumber();

    return () => {
      agentSearch.onResultDelete();
      actions.deleteStateAgentPartnerIdNumber();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={
          <BackNavigationArrow>
            <h2>{t("agent.titles.create")}</h2>
          </BackNavigationArrow>
        }
      >
        <AgentForm
          onCreate={actions.createAgent}
          initialPartnerIdNumber={agentPartnerIdNumber}
          agentSearch={agentSearch}
        />
      </Card>
    </ContentWrapper>
  );
};
