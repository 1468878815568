import { Route, Routes } from "react-router-dom";
import { NESTED_ROUTE_PATH, WILDCARD_ROUTE } from "../common/constants";
import AttachmentBoundary from "../common/modules/attachment/AttachmentBoundary";
import { ATTACHMENT_BOUNDARY_TYPE } from "../common/modules/attachment/enums";
import ClientLandingPage from "../common/pages/ClientLandingPage/ClientLandingPage";
import NotFound from "../common/pages/NotFound/NotFound";
import AnonymousWrapper from "../common/security/authentication/AnonymousWrapper";
import AuthenticatedWrapper from "../common/security/authentication/AuthenticatedWrapper";
import { AFFILIATE_PARTNER_ROUTE_PREFIX } from "../modules/affiliate/paths";
import AffiliatePartnerRoutes from "../modules/affiliate/routes";
import { AGENT_DIRECTORATE_ROUTE_PREFIX, AGENT_ROUTE_PATHS, AGENT_ROUTE_PREFIX } from "../modules/agent/paths";
import { AgentDirectorateRoutes, AgentRoutes } from "../modules/agent/routes";
import { AUTH_ROUTE_PREFIX } from "../modules/auth/paths";
import AuthRoutes from "../modules/auth/routes";
import { BUG_REPORT_ROUTE_PATHS } from "../modules/bugreport/paths";
import { CALC_BLACKLIST_ROUTE_PREFIX } from "../modules/calculator/blacklist/paths";
import CalcBlacklistRoutes from "../modules/calculator/blacklist/routes";
import { CALC_ROUTE_PATHS, CALC_ROUTE_PREFIX } from "../modules/calculator/paths";
import CalcRoutes from "../modules/calculator/routes";
import { ADMIN_CALC_ROUTE_PATHS, ADMIN_CALC_ROUTE_PREFIX } from "../modules/calculator/settings/paths";
import CalcSettingsRoutes from "../modules/calculator/settings/routes";
import { CLIENT_ROUTE_PATHS, CLIENT_ROUTE_PREFIX } from "../modules/client/paths";
import ClientRoutes from "../modules/client/routes";
import { COMMISSIONS_BATCH_ROUTE_PATHS, COMMISSIONS_BATCH_ROUTE_PREFIX } from "../modules/commissions/batch/paths";
import CommissionsBatchRoutes from "../modules/commissions/batch/routes";
import { COMMISSIONS_LEVEL_ROUTE_PATHS, COMMISSIONS_LEVEL_ROUTE_PREFIX } from "../modules/commissions/level/paths";
import CommissionsLevelRoutes from "../modules/commissions/level/routes";
import { POSTPONED_COMMISSIONS_ROUTE_PREFIX } from "../modules/commissions/postponed/paths";
import PostponedCommissionsRoutes from "../modules/commissions/postponed/routes";
import { COMPLICITY_ROUTE_PREFIX } from "../modules/complicity/paths";
import ComplicityRoutes from "../modules/complicity/routes";
import { CONTRACT_ROUTE_PATHS, CONTRACT_ROUTE_PREFIX } from "../modules/contract/paths";
import ContractRoutes from "../modules/contract/routes";
import { CONTRACT_UPDATE_ROUTE_PREFIX } from "../modules/contractupdate/paths";
import ContractUpdateRoutes from "../modules/contractupdate/routes";
import { COVERAGE_LIMIT_ROUTE_PREFIX } from "../modules/coveragelimit/paths";
import CoverageLimitRoutes from "../modules/coveragelimit/routes";
import { DASHBOARD_ROUTE_PATHS, DASHBOARD_ROUTE_PREFIX } from "../modules/dashboard/paths";
import DashboardRoutes from "../modules/dashboard/routes";
import { DOCUMENT_NODE_ROUTE_PATHS, DOCUMENT_NODE_ROUTE_PREFIX } from "../modules/documents/paths";
import DocumentNodeRoutes from "../modules/documents/routes";
import { CONFIG_PROPERTY_ROUTE_PREFIX } from "../modules/dynamicconfig/paths";
import ConfigPropertyRoutes from "../modules/dynamicconfig/routes";
import { FINANCIAL_MEDIATION_ROUTE_PATHS, FINANCIAL_MEDIATION_ROUTE_PREFIX } from "../modules/financialmediation/paths";
import FinancialMediationRoutes from "../modules/financialmediation/routes";
import { INSTITUTION_ROUTE_PREFIX } from "../modules/institution/paths";
import InstitutionRoutes from "../modules/institution/routes";
import { JOB_ROUTE_PREFIX } from "../modules/jobs/paths";
import JobRoutes from "../modules/jobs/routes";
import { LIFE_INSURENCE_TARIFF_ROUTE_PREFIX } from "../modules/lifeinsurancetariff/paths";
import LifeInsuranceTariffRoutes from "../modules/lifeinsurancetariff/routes";
import { NOTIFICATION_ROUTE_PREFIX } from "../modules/notifications/paths";
import NotificationRoutes from "../modules/notifications/routes";
import { PARTNER_ROUTE_PREFIX } from "../modules/partner/paths";
import PartnerRoutes from "../modules/partner/routes";
import { PRODUCT_ROUTE_PREFIX } from "../modules/product/paths";
import ProductRoutes from "../modules/product/routes";
import ConfirmUserContainer from "../modules/user/containers/ConfirmUserContainer";
import RequestPasswordResetContainer from "../modules/user/containers/RequestPasswordResetContainer";
import ResetPasswordContainer from "../modules/user/containers/ResetPasswordContainer";
import UpdateEmailContainer from "../modules/user/containers/UpdateEmailContainer";
import {
  ADMIN_USER_ROUTE_PREFIX,
  CURRENT_USER_ROUTE_PREFIX,
  USER_ROUTE_PATHS,
  USER_ROUTE_PREFIX
} from "../modules/user/paths";
import { AdminUserRoutes, CurrentUserRoutes, UserRoutes } from "../modules/user/routes";
import { VEHICLE_BRAND_ROUTE_PREFIX } from "../modules/vehicle/brand/paths";
import VehicleBrandRoutes from "../modules/vehicle/brand/routes";
import { VEHICLE_ENUM_ROUTE_PREFIX } from "../modules/vehicle/paths";
import VehicleEnumRoutes from "../modules/vehicle/routes";

export const agentRoleRoutes = (
  <Routes>
    <Route path={AUTH_ROUTE_PREFIX + NESTED_ROUTE_PATH} element={<AuthRoutes />} />

    <Route
      path={DASHBOARD_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<DashboardRoutes />} />}
    />

    <Route
      path={NOTIFICATION_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<NotificationRoutes />} />}
    />

    <Route
      path={CLIENT_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<ClientRoutes />} />}
    />

    <Route
      path={CONTRACT_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<ContractRoutes />} />}
    />

    <Route
      path={FINANCIAL_MEDIATION_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<FinancialMediationRoutes />} />}
    />

    <Route path={CALC_ROUTE_PREFIX + NESTED_ROUTE_PATH} element={<AuthenticatedWrapper component={<CalcRoutes />} />} />

    <Route
      path={PARTNER_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<PartnerRoutes />} />}
    />

    <Route
      path={AGENT_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<AgentRoutes />} />}
    />

    <Route
      path={AGENT_DIRECTORATE_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<AgentDirectorateRoutes />} />}
    />

    <Route
      path={DOCUMENT_NODE_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<DocumentNodeRoutes />} />}
    />

    <Route
      path={COMMISSIONS_BATCH_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<CommissionsBatchRoutes />} />}
    />

    <Route
      path={POSTPONED_COMMISSIONS_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<PostponedCommissionsRoutes />} />}
    />

    <Route
      path={COMMISSIONS_LEVEL_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<CommissionsLevelRoutes />} />}
    />

    <Route
      path={ADMIN_USER_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<AdminUserRoutes />} />}
    />

    <Route
      path={CURRENT_USER_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<CurrentUserRoutes />} />}
    />

    <Route path={USER_ROUTE_PREFIX + NESTED_ROUTE_PATH} element={<UserRoutes />} />

    <Route
      path={INSTITUTION_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<InstitutionRoutes />} />}
    />

    <Route
      path={PRODUCT_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<ProductRoutes />} />}
    />

    <Route
      path={AFFILIATE_PARTNER_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<AffiliatePartnerRoutes />} />}
    />

    <Route
      path={ADMIN_CALC_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<CalcSettingsRoutes />} />}
    />

    <Route
      path={CONTRACT_UPDATE_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<ContractUpdateRoutes />} />}
    />

    <Route
      path={LIFE_INSURENCE_TARIFF_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<LifeInsuranceTariffRoutes />} />}
    />

    <Route
      path={VEHICLE_ENUM_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<VehicleEnumRoutes />} />}
    />

    <Route
      path={VEHICLE_BRAND_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<VehicleBrandRoutes />} />}
    />

    <Route
      path={COVERAGE_LIMIT_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<CoverageLimitRoutes />} />}
    />

    <Route
      path={COMPLICITY_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<ComplicityRoutes />} />}
    />

    <Route path={JOB_ROUTE_PREFIX + NESTED_ROUTE_PATH} element={<AuthenticatedWrapper component={<JobRoutes />} />} />

    <Route
      path={CALC_BLACKLIST_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<CalcBlacklistRoutes />} />}
    />

    <Route
      path={CONFIG_PROPERTY_ROUTE_PREFIX + NESTED_ROUTE_PATH}
      element={<AuthenticatedWrapper component={<ConfigPropertyRoutes />} />}
    />

    <Route path={WILDCARD_ROUTE} element={<AuthenticatedWrapper component={<NotFound />} />} />
  </Routes>
);

export const clientRoleRoutes = (
  <Routes>
    <Route path={AUTH_ROUTE_PREFIX + NESTED_ROUTE_PATH} element={<AuthRoutes />} />

    <Route path={DASHBOARD_ROUTE_PREFIX} element={<AuthenticatedWrapper component={<ClientLandingPage />} />} />

    <Route path={USER_ROUTE_PATHS.confirm.path} element={<ConfirmUserContainer />} />

    <Route path={USER_ROUTE_PATHS.updateEmail.path} element={<UpdateEmailContainer />} />

    <Route
      path={USER_ROUTE_PATHS.requestPasswordReset.path}
      element={<AnonymousWrapper component={<RequestPasswordResetContainer />} />}
    />

    <Route path={USER_ROUTE_PATHS.resetPassword.path} element={<ResetPasswordContainer />} />

    <Route path={WILDCARD_ROUTE} element={<AuthenticatedWrapper component={<NotFound />} />} />
  </Routes>
);

export const attachmentRoutes = (
  <Routes>
    <Route
      path={AGENT_ROUTE_PATHS.attachment.to}
      element={<AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.AGENT} />} />}
    />

    <Route
      path={BUG_REPORT_ROUTE_PATHS.attachment.to}
      element={<AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.BUG_REPORT} />} />}
    />

    <Route
      path={CALC_ROUTE_PATHS.draftAttachment.to}
      element={
        <AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.CALCULATOR_DRAFT} />} />
      }
    />

    <Route
      path={CALC_ROUTE_PATHS.resultAttachment.to}
      element={
        <AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.CALCULATOR_RESULT} />} />
      }
    />

    <Route
      path={ADMIN_CALC_ROUTE_PATHS.attachmentConf.to}
      element={
        <AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.CALCULATOR_SETTING} />} />
      }
    />

    <Route
      path={CLIENT_ROUTE_PATHS.attachment.to}
      element={<AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.CLIENT} />} />}
    />

    <Route
      path={CLIENT_ROUTE_PATHS.importResultFile.to}
      element={
        <AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.CLIENT_IMPORT_RESULT} />} />
      }
    />

    <Route
      path={COMMISSIONS_BATCH_ROUTE_PATHS.attachment.to}
      element={
        <AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.COMMISSIONS_BATCH} />} />
      }
    />

    <Route
      path={COMMISSIONS_LEVEL_ROUTE_PATHS.attachment.to}
      element={
        <AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.COMMISSIONS_LEVEL} />} />
      }
    />

    <Route
      path={AGENT_ROUTE_PATHS.commissionsSettingsAttachment.to}
      element={
        <AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.COMMISSIONS_SETTINGS} />} />
      }
    />

    <Route
      path={AGENT_ROUTE_PATHS.commissionsOutput.to}
      element={
        <AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.COMMISSIONS_OUTPUT} />} />
      }
    />

    <Route
      path={CONTRACT_ROUTE_PATHS.attachment.to}
      element={<AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.CONTRACT} />} />}
    />

    <Route
      path={CONTRACT_ROUTE_PATHS.importResultFile.to}
      element={
        <AuthenticatedWrapper
          component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.CONTRACT_IMPORT_RESULT} />}
        />
      }
    />

    <Route
      path={DASHBOARD_ROUTE_PATHS.noticeAttachment.to}
      element={
        <AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.DASHBOARD_NOTICE} />} />
      }
    />

    <Route
      path={DOCUMENT_NODE_ROUTE_PATHS.attachment.to}
      element={
        <AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.DOCUMENT_NODE} />} />
      }
    />

    <Route
      path={FINANCIAL_MEDIATION_ROUTE_PATHS.file.to}
      element={
        <AuthenticatedWrapper component={<AttachmentBoundary type={ATTACHMENT_BOUNDARY_TYPE.FINANCIAL_MEDIATION} />} />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<AuthenticatedWrapper component={<NotFound />} />} />
  </Routes>
);
